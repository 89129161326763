import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Particles from "react-particles-js"
import Header from "../components/header"
import Footer from "../components/footer"

const Hero = () => (
  <section className="container hero bg-dark-main flex-center-col">
    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
      <Particles height="100%" />
    </div>
    <div style={{ zIndex: 2 }} className="flex-center-col">
      <h1
        className="mt-5 mb-3 p-2 text-rainbow"
        style={{ lineHeight: "10rem" }}
      >
        พบกัน เร็วๆ นี้
      </h1>
      <h5 className="text-white sans">เนื้อหาส่วนนี้ยังไม่พร้อมใช้งาน</h5>
    </div>
  </section>
)
const ComingSoonPage = () => (
  <Layout>
    <SEO title="THACO | Coming Soon" name="coming-soon" />
    <Header page="coming-soon" />
    <Hero />
    <Footer />
  </Layout>
)

export default ComingSoonPage
